/*
 * Black Kiwi Scripts File
 */

import barba from "@barba/core";
import barbaCss from "@barba/css";
//import jQuery from "jquery/dist/jquery.slim";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/all";

// tell Barba to use the css plugin
barba.use(barbaCss);

// register the plugin first
gsap.registerPlugin(ScrollToPlugin);

// init Barba
barba.init({
	transitions: [
		{
			leave(data) {
				return gsap.to(data.current.container, {
					opacity: 0,
				});
			},
			enter(data) {
				return gsap.from(data.next.container, {
					opacity: 0,
				});
			},
		},
	],
});

barba.hooks.enter(() => {
	window.scrollTo(0, 0);
	const elementContact = document.querySelector("#contact");
	const triggerContact = document.querySelector(".smoothscroll-contact");

	triggerContact.addEventListener("click", () => {
		gsap.to(window, {
			duration: 1.2,
			ease: "power4.inOut",
			scrollTo: elementContact,
		});
	});

	const elementWork = document.querySelector("#work");
	const triggerWork = document.querySelector(".smoothscroll-work");

	triggerWork.addEventListener("click", () => {
		gsap.to(window, {
			duration: 1.2,
			ease: "power4.inOut",
			scrollTo: elementWork,
		});
	});

	function detectColorScheme() {
		// Local storage is used to override OS theme settings
		const theme =
			localStorage.getItem("theme") ||
			(window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)").matches
				? "dark"
				: "light");

		if (theme === "dark") {
			document.documentElement.setAttribute("data-theme", "dark");
		}
	}
	detectColorScheme();

	const toggleSwitch = document.querySelector(
		'#theme-switch input[type="checkbox"]'
	);
	if (toggleSwitch) {
		// Function that changes the theme, and sets a localStorage variable to track the theme between page loads
		function switchTheme(e) {
			const theme = e.target.checked ? "dark" : "light";
			localStorage.setItem("theme", theme);
			document.documentElement.setAttribute("data-theme", theme);
			toggleSwitch.checked = e.target.checked;
		}

		toggleSwitch.addEventListener("change", switchTheme);

		if (document.documentElement.getAttribute("data-theme") === "dark") {
			toggleSwitch.checked = true;
		}
	}
});

document.addEventListener("DOMContentLoaded", function () {
	/* DARKMODE SWITCH */
	// https://stackoverflow.com/a/56550819

	function detectColorScheme() {
		// Local storage is used to override OS theme settings
		const theme =
			localStorage.getItem("theme") ||
			(window.matchMedia &&
			window.matchMedia("(prefers-color-scheme: dark)").matches
				? "dark"
				: "light");

		if (theme === "dark") {
			document.documentElement.setAttribute("data-theme", "dark");
		}
	}
	detectColorScheme();

	const toggleSwitch = document.querySelector(
		'#theme-switch input[type="checkbox"]'
	);
	if (toggleSwitch) {
		// Function that changes the theme, and sets a localStorage variable to track the theme between page loads
		function switchTheme(e) {
			const theme = e.target.checked ? "dark" : "light";
			localStorage.setItem("theme", theme);
			document.documentElement.setAttribute("data-theme", theme);
			toggleSwitch.checked = e.target.checked;
		}

		toggleSwitch.addEventListener("change", switchTheme);

		if (document.documentElement.getAttribute("data-theme") === "dark") {
			toggleSwitch.checked = true;
		}
	}

	if (document.querySelector(".smoothscroll-contact")) {
		const elementContact = document.querySelector("#contact");

		const triggerContact = document.querySelector(".smoothscroll-contact");
		triggerContact.addEventListener("click", () => {
			gsap.to(window, {
				duration: 1.2,
				ease: "power4.inOut",
				scrollTo: elementContact,
			});
		});
	}

	if (document.querySelector(".smoothscroll-work")) {
		const elementWork = document.querySelector("#work");

		const triggerWork = document.querySelector(".smoothscroll-work");
		triggerWork.addEventListener("click", () => {
			gsap.to(window, {
				duration: 1.2,
				ease: "power4.inOut",
				scrollTo: elementWork,
			});
		});
	}

	/* START GSAP PARALLAX SCROLL */
	/* thx http://orsolina28.it/ */
	window.addEventListener("scroll", function () {
		var elementScroll = document.querySelectorAll("[data-parallax]");
		var scroll = window.scrollY;
		scrollHandler(scroll);

		function scrollHandler(scroll) {
			var scroll = scroll;
			elementScroll.forEach(function (el) {
				var coefficent = el.getAttribute("data-parallax");
				var offsetTop = el.offsetTop - window.pageYOffset;
				var translateY = (offsetTop * -coefficent) / 8;
				gsap.to(el, 1.2, { y: translateY });
			});
		}
	});
});
